import api from "@/api";
import router from "@/router";
import { defineComponent, reactive, toRefs, ref } from "@vue/runtime-core";
import { nextTick } from "@vue/runtime-dom";
import { ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { useStore } from "vuex";
export default defineComponent({
  name: 'MineSitePassword',
  props: {
    type: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0,
      required: true
    },
    onClose: Function
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { show, type, id } = toRefs(props)
    const formRef = ref({})
    const passwordForm = reactive({
      id: 0,
      password: '',
      confirmPassword: '',
      oldPassword: ''
    })

    const store = useStore()

    const passwordRules = {
      oldPassword: [
        {required: true, message: '请输入原始密码'}
      ],
      password: [
        {required: true, message: '请输入新密码'}
      ],
      confirmPassword: [
        {required: true, message: '请输入确认密码'}
      ]
    }

    function close(): void {
      nextTick(() => {
        const __form = formRef.value as any
        __form.resetFields()
        emit('close')
      })
    }

    function submit(): void {
      nextTick(() => {
        const __form = formRef.value as ElFormItemContext
        __form.validate(v => {
          if(v) {
            const obj = Object.assign({}, passwordForm) as UpdatePassword
            obj.id = id.value
            api.updatePassword(obj).then(() => {
              ElNotification({
                type: 'success',
                message: '修改密码成功 请重新登录'
              })
              close()
              setTimeout(() => {
                store.dispatch('resetToken')
                router.push('/')
              }, 1000)
            })
          }else {
            return false
          }
        })
      })
    }

    return () => <el-dialog
      title="重设密码"
      v-model={show.value}
      width="500px"
      before-close={close}
      custom-class="mine_site-dialog"
    >
      <el-form
        ref={ formRef }
        model={passwordForm}
        rules={passwordRules}
        label-width="120px"
        label-position="top"
      >
        <el-form-item label="原始密码" prop="oldPassword">
          <el-input v-model={passwordForm.oldPassword} clearable placeholder="原始密码"/>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model={passwordForm.password} clearable placeholder="新密码"/>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model={passwordForm.confirmPassword} clearable placeholder="确认密码"/>
        </el-form-item>
      </el-form>
      <div class="mine_site-dialog-submit" onClick={submit}>确定修改</div>
    </el-dialog>
  }
})

export interface UpdatePassword {
  id: number
  oldPassword: string
  password: string
  confirmPassword: string
}

