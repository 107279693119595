import { UserInfoRow, UserTeamRow } from "@/store/modules/user";
import { parseTime } from "@/utils";
import { ElMessageBox } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import './index.scss'
import MineSitePassword from './components/password'

@Options({
  computed: {
    ...mapGetters(['GetUserTeam', 'GetUserInfo'])
  }
})
export default class MineTeamInfo extends Vue {
  public GetUserTeam?: UserTeamRow // 团队信息
  public GetUserInfo?: UserInfoRow
  public passwordShow = false

  logout(): void {
    ElMessageBox.confirm('确定退出登录？', '退出登录', {
      type: 'warning'
    }).then(() => {
      this.$store.dispatch('resetToken')
      this.$router.go(0)
      if(document.body.clientWidth < 900) {
        // TODO 跳转的mobile
        window.location.href = `http://mobile.taosseract.com/?removeToken=1`
      }
    }).catch(() => false)
  }

  render(): JSX.Element {
    return <div class="team_info">
      <div class="team_info-cell">
        <div class="team_info-cell-title">团体信息</div>
        <el-row>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>会员编号</span>
            <span>{this.GetUserTeam?.code}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>注册时间</span>
            <span>{parseTime(new Date(this.GetUserTeam?.createTime as string).getTime())}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>团体名称</span>
            <span>{this.GetUserTeam?.title}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>账号限制</span>
            <span>{this.GetUserTeam?.teamCount}/{this.GetUserTeam?.accountNumber}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>团体地址</span>
            <span>{this.GetUserTeam?.address}</span>
          </el-col>
        </el-row>
      </div>
      <div class="team_info-cell">
        <div class="team_info-cell-title">团队负责人信息</div>
        <el-row>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>姓名</span>
            <span>{this.GetUserTeam?.leaderName}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>性别</span>
            <span>{this.GetUserTeam?.gender === 1 ? '男' : '女'}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>手机号</span>
            <span>{this.GetUserTeam?.phone}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>邮箱</span>
            <span>{this.GetUserTeam?.email}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>身份证</span>
            <span>{this.GetUserTeam?.cardId}</span>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={12} xl={12} >
            <span>密码</span>
            <span>********</span>
            <span class="pointer" onClick={() => this.passwordShow = true}>修改</span>
          </el-col>
        </el-row>
        <div class="team_info-submit">
          <div class="team_info-submit-btn" style="margin-right: 20px" onClick={this.logout}>退出登录</div>
        </div>
      </div>
      <MineSitePassword id={this.GetUserInfo?.id} show={this.passwordShow} onClose={() => this.passwordShow = false} />
    </div>
  }
}
